<template>
    <main class="main-page">
        <template v-if="showHeader">
            <section class="page-section">
                <div class="container">
                    <div class="grid mb-4 card nice-shadow-6 justify-content-between align-items-center">
                        <div class="col ">
                            <div class="">
                                <div class="flex align-items-center ">
                                    <div class="mr-3">
                                        <Button @click="$router.go(-1)" label="" icon="pi pi-arrow-left"
                                            class="p-button-sm p-button-rounded px-4" />
                                    </div>
                                    <div>
                                        <div class="text-2xl text-primary font-bold">Transactions Report</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </template>
        <section class="page-section mb-3">
            <div class="container">
                <div class="grid ">
                    <div class="col-12 md:col-12 comp-grid">
                        <div class="p-2">
                            <span class="p-input-icon-left w-full">
                                <i class="pi pi-search" />
                                <InputText placeholder="Search" class="w-full" :value="searchText"
                                    @input="debounce(() => { searchText = $event.target.value })" />
                            </span>
                        </div>
                    </div>
                    <div class="md:col-3 col-12 comp-grid">
                        <div class="p-0">
                            <div class="p-0">
                                <div class="p-2">
                                    <Calendar :showIcon="true" :manualInput="false" :showButtonBar="false"
                                        dateFormat="yy-mm-dd" mask="YYYY-MM-DD" class="w-full"
                                        v-model="filters.from_date.value" placeholder="From date" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="md:col-3 col-12 comp-grid">
                        <div class="p-0">
                            <div class="p-0">
                                <div class="p-2">
                                    <Calendar :showIcon="true" :manualInput="false" :showButtonBar="false"
                                        dateFormat="yy-mm-dd" mask="YYYY-MM-DD" class="w-full"
                                        v-model="filters.to_date.value" placeholder="To date" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="md:col-3 col-12 comp-grid">
                        <div class="">
                            <api-data-source @loaded="(response)=> filters.transactions_network.options=response"
                                api-path="components_data/transactions_network_option_list">
                                <template v-slot="req">
                                    <div class="p-2">
                                        <Dropdown class="w-full" :loading="req.loading"
                                            v-model="filters.transactions_network.value" optionLabel="label"
                                            optionValue="value" :options="filters.transactions_network.options"
                                            placeholder="Network">
                                        </Dropdown>
                                    </div>
                                </template>
                            </api-data-source>
                        </div>
                    </div>
                    <div class="md:col-3 col-12 comp-grid">
                        <div class="p-0">
                            <api-data-source @loaded="(response)=> filters.transaction_status.options=response"
                                api-path="components_data/transaction_status_option_list">
                                <template v-slot="req">
                                    <div class="p-2">
                                        <Dropdown class="w-full" :loading="req.loading"
                                            v-model="filters.transaction_status.value" optionLabel="label"
                                            optionValue="value" :options="filters.transaction_status.options"
                                            placeholder="Status">
                                        </Dropdown>
                                    </div>
                                </template>
                            </api-data-source>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section class="page-section mb-3">
            <div class="container">
                <api-data-source :first-record="true"
                    :api-path="`transactions/getstats?from_date=${$utils.formatDate(filters.from_date.value)}&to_date=${$utils.formatDate(filters.to_date.value)}&transactions_network=${filters.transactions_network.value}&transaction_status=${filters.transaction_status.value}`"
                    v-slot="{ loading, response, load: reload }">
                    <div class="grid">
                        <div class="col-12 md:col-3 comp-grid">
                            <div class="card bg-blue-100">
                                <div class="font-medium text-lg mb-3">
                                    Total Amount (GHS)
                                </div>
                                <div class="flex align-items-center justify-content-between mb-3">
                                    <div>
                                        <div class="font-bold text-3xl mb-2">
                                            {{ $utils.formatNumber(response.total_transactions_amount, 2) }}
                                        </div>
                                        <div>
                                            Count: {{ $utils.formatNumber(response.total_transactions_count) }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 md:col-3 comp-grid">
                            <div class="card bg-green-100">
                                <div class="font-medium text-lg mb-3">
                                    Success (GHS)
                                </div>
                                <div class="flex align-items-center justify-content-between mb-3">
                                    <div>
                                        <div class="font-bold text-3xl mb-2">
                                            {{ $utils.formatNumber(response.success_transactions_amount, 2) }}
                                        </div>
                                        <div>
                                            Count: {{ $utils.formatNumber(response.success_transactions_count) }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 md:col-3 comp-grid">
                            <div class="card bg-pink-100">
                                <div class="font-medium text-lg mb-3">
                                    Failed (GHS)
                                </div>
                                <div class="flex align-items-center justify-content-between mb-3">
                                    <div>
                                        <div class="font-bold text-3xl mb-2">
                                            {{ $utils.formatNumber(response.failed_transactions_amount, 2) }}
                                        </div>
                                        <div>
                                            Count: {{$utils.formatNumber(response.failed_transactions_count) }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 md:col-3 comp-grid">
                            <div class="card bg-yellow-100">
                                <div class="font-medium text-lg mb-3">
                                    Pending (GHS)
                                </div>
                                <div class="flex align-items-center justify-content-between mb-3">
                                    <div>
                                        <div class="font-bold text-3xl mb-2">
                                            {{ $utils.formatNumber(response.pending_transactions_amount, 2) }}
                                        </div>
                                        <div> Count: {{$utils.formatNumber(response.pending_transactions_count) }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </api-data-source>
            </div>
        </section>
        <section class="page-section ">
            <div class="container">
                <div class="grid ">
                    <div class="col comp-grid">
                        <div class="flex align-items-start justify-content-start">
                            <div v-if="searchText" :class="filterTagClass">
                                <Chip class="font-medium px-2 py-1" removable @remove="clearSearch()"><b
                                        class="mr-2">Search: </b> {{ searchText }}
                                </Chip>
                            </div>
                            <div v-if="filterHasValue(filters.from_date)" :class="filterTagClass">
                                <Chip class="font-medium px-2 py-1" removable @remove="removeFilter(filters.from_date)">
                                    <b class="mr-2">From: </b> {{ getFilterLabel(filters.from_date) }}
                                </Chip>
                            </div>
                            <div v-if="filterHasValue(filters.to_date)" :class="filterTagClass">
                                <Chip class="font-medium px-2 py-1" removable @remove="removeFilter(filters.to_date)">
                                    <b class="mr-2">To: </b> {{ getFilterLabel(filters.to_date) }}
                                </Chip>
                            </div>
                            <div v-if="filterHasValue(filters.transactions_network)" :class="filterTagClass">
                                <Chip class="font-medium px-2 py-1" removable
                                    @remove="removeFilter(filters.transactions_network)">
                                    <b class="mr-2">Network: </b> {{ getFilterLabel(filters.transactions_network) }}
                                </Chip>
                            </div>
                            <div v-if="filterHasValue(filters.transaction_status)" :class="filterTagClass">
                                <Chip class="font-medium px-2 py-1" removable
                                    @remove="removeFilter(filters.transaction_status)">
                                    <b class="mr-2">Transaction Status: </b> {{
                                    getFilterLabel(filters.transaction_status) }}
                                </Chip>
                            </div>
                        </div>
                        <div>
                            <template v-if="showBreadcrumbs && $route.query.tag">
                                <Breadcrumb :home="{icon: 'pi pi-home', to: '/transactions'}" :model="pageBreadCrumb" />
                            </template>
                            <!-- page records template -->
                            <div class="page-records" v-if="!loading">
                                <DataTable :lazy="true" :loading="loading" :value="records" dataKey="transaction_id"
                                    @sort="onSort($event)" class="" :showGridlines="false" :rowHover="true"
                                    responsiveLayout="stack">
                                    <Column field="amount" header="Amount" :sortable="true">
                                        <template #body="{data}">
                                            {{ $utils.formatNumber(data.amount, 2) }}
                                        </template>
                                    </Column>
                                    <Column field="msisdn" header="Msisdn">
                                        <template #body="{data}">
                                            {{ data.msisdn }}
                                        </template>
                                    </Column>
                                    <Column field="external_transaction_id" header="Ex.Trans.ID">
                                        <template #body="{data}">
                                            {{ data.external_transaction_id }}
                                        </template>
                                    </Column>
                                    <Column field="payment_method" header="Method">
                                        <template #body="{data}">
                                            {{ data.payment_method }}
                                        </template>
                                    </Column>
                                    <Column field="network" header="Network" :sortable="true">
                                        <template #body="{data}">
                                            {{ data.network }}
                                        </template>
                                    </Column>
                                    <Column field="transaction_status" header="Status" :sortable="true">
                                        <template #body="{data}">
                                            {{ data.transaction_status }}
                                        </template>
                                    </Column>
                                    <Column field="date_created" header="Date Created" :sortable="true">
                                        <template #body="{data}">
                                            {{$utils.humanDatetime( data.date_created )}}
                                        </template>
                                    </Column>
                                    <Column headerStyle="width: 3em" headerClass="text-center">
                                        <template #body="{data}">
                                            <SplitButton class="p-button p-button-text p-button-sm"
                                                :model="getActionMenuModel(data)">
                                                <i></i>
                                            </SplitButton>
                                        </template>
                                    </Column>
                                </DataTable>
                            </div>
                            <!-- page loading indicator -->
                            <template v-if="loading">
                                <div>
                                    <DataTable :value="[1,2,3,4,5]" responsiveLayout="scroll">
                                        <Column style="width:3rem">
                                            <template #body>
                                                <Skeleton size="2rem" />
                                            </template>
                                        </Column>
                                        <Column>
                                            <template #body>
                                                <Skeleton />
                                            </template>
                                        </Column>
                                        <Column style="width:3rem">
                                            <template #body>
                                                <Skeleton size="2rem" />
                                            </template>
                                        </Column>
                                    </DataTable>
                                </div>
                            </template>
                            <!-- end of page loading indicator-->
                            <!-- Empty record -->
                            <template v-if="pageReady && !records.length">
                                <div class="p-3 my-3 text-500 text-lg font-medium text-center">
                                    No record found
                                </div>
                            </template>
                            <!-- end of empty record-->
                            <!-- pagination component-->
                            <template v-if="showFooter">
                                <div class="">
                                    <div v-show="pageReady">
                                        <div class="flex justify-content-between">
                                            <div class="flex justify-content-center flex-grow-0">
                                                <div class="m-2" v-if="exportButton && records.length">
                                                    <Button @click="(event)=> $refs.exportMenu.toggle(event)" label=""
                                                        title="Export" icon="pi pi-print" />
                                                    <Menu ref="exportMenu" popup :model="pageExportFormats" />
                                                </div>
                                            </div>
                                            <div v-if="paginate && totalPages > 1" class="flex-grow-1">
                                                <Paginator class="border-none bg-transparent py-3"
                                                    :first="recordsPosition"
                                                    @page="(event)=>{pagination.page = event.page + 1}"
                                                    :rows="pagination.limit" :totalRecords="totalRecords">
                                                    <template #start>
                                                        <span class="text-sm text-gray-500 px-2">
                                                            Records <b>{{ recordsPosition }} of {{ totalRecords }}</b>
                                                        </span>
                                                    </template>
                                                    <template #end>
                                                    </template>
                                                </Paginator>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </template>
                            <!-- end of pagination component-->
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </main>
</template>
<script setup>
    import { reactive, computed, toRefs, onMounted } from 'vue';
    import { utils } from '@/utils';
    import { useApp } from '@/composables/app.js';
    import { useListPage } from '@/composables/listpage.js';
    const props = defineProps({
        primaryKey: {
            type: String,
            default: 'transaction_id',
        },
        pageName: {
            type: String,
            default: 'transactions',
        },
        routeName: {
            type: String,
            default: 'transactionslist',
        },
        apiPath: {
            type: String,
            default: 'transactions/index',
        },
        paginate: {
            type: Boolean,
            default: true,
        },
        showHeader: {
            type: Boolean,
            default: true,
        },
        showFooter: {
            type: Boolean,
            default: true,
        },
        showBreadcrumbs: {
            type: Boolean,
            default: true,
        },
        exportButton: {
            type: Boolean,
            default: true,
        },
        importButton: {
            type: Boolean,
            default: false,
        },
        multiCheckbox: {
            type: Boolean,
            default: false,
        },
        emptyRecordMsg: {
            type: String,
            default: "No record found",
        },
        titleBeforeDelete: {
            type: String,
            default: "Delete record",
        },
        msgBeforeDelete: {
            type: String,
            default: "Are you sure you want to delete this record?",
        },
        msgAfterDelete: {
            type: String,
            default: "Record deleted successfully",
        },
        page: {
            type: Number,
            default: 1,
        },
        limit: {
            type: Number,
            default: 15,
        },
        mergeRecords: { // for infinite loading
            type: Boolean,
            default: false,
        },
        search: {
            type: String,
            default: '',
        },
        fieldName: null,
        fieldValue: null,
        sortBy: {
            type: String,
            default: '',
        },
        sortType: {
            type: String,
            default: '', //desc or asc
        },
        isSubPage: {
            type: Boolean,
            default: false,
        },
        filterTagClass: {
            type: String,
            default: 'p-2 text-500 m-1 mb-3',
        }
    });
    const app = useApp();
    const filters = reactive({
        from_date: {
            value: '',
            valueType: 'single-date',
            options: [],
        },
        to_date: {
            value: '',
            valueType: 'single-date',
            options: [],
        },
        transactions_network: {
            value: '',
            valueType: 'single',
            options: [],
        },
        transaction_status: {
            value: '',
            valueType: 'single',
            options: [],
        }
    });
    // init list page hook
    const page = useListPage(props, filters);
    const { state, methodsProps } = page;
    const {
        totalRecords,
        recordCount,
        loading,
        selectedItems,
        pagination,
        searchText,
        pageReady
    } = toRefs(page.state);
    const {
        records,
        apiUrl,
        currentRecord,
        pageBreadCrumb,
        canLoadMore,
        finishedLoading,
        totalPages,
        recordsPosition
    } = page.computedProps;
    const {
        load,
        reload,
        loadNextPage,
        loadPreviousPage,
        exportPage,
        clearSearch,
        debounce,
        onSort,
        deleteItem,
        setCurrentRecord,
        isCurrentRecord,
        removeFilter,
        getFilterLabel,
        filterHasValue,
        importComplete
    } = page.methods;
    const pageExportFormats = [
        {
            label: 'Excel',
            icon: 'pi pi-file-excel text-green-500',
            command: () => { app.exportPageRecords('excel', apiUrl, props.pageName, 'xlsx') }
        }
    ];
    function getActionMenuModel(data) {
        return [
            {
                label: "View",
                command: (event) => { app.openPageDialog({ page: 'transactions/view', url: `/transactions/view/${data.transaction_id}`, persistent: true, closeBtn: true }) },
                icon: "pi pi-eye"
            }
        ]
    }
    onMounted(() => {
        const pageTitle = "Transactions";
        app.setPageTitle(props.routeName, pageTitle);
        load();
    });
</script>
<style scoped>
</style>